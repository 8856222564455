
import $app from '@/plugins/modules';
import { Vue, Component, Prop, Watch } from 'vue-property-decorator'
import { IPatient } from '../types';

@Component
export default class CustomerCreateDialog extends Vue {
  @Prop({ default: () => false })
  readonly value: boolean | undefined;

  @Prop({ default: () => undefined })
  readonly parentId: number | undefined;

  patientFirstName = '';
  patientLastName = '';
  patientPhone = '';
  state = 0;
  processing = false;

  @Watch('value')
  onShow() {
    if (this.value) {
      this.patientFirstName = '';
      this.patientLastName = '';
      this.patientPhone = '';
      this.state = 0;
    }
  }

  get visible(): boolean {
    return this.value || false;
  }

  set visible(value: boolean) {
    this.$emit('input', value);
  }

  async store() {
    this.processing = true;
    try {
      let patient: IPatient = {
        patientId: null,
        patientParent: this.parentId || null,
        patientVIP: 0,
        patientFirstName: this.patientFirstName,
        patientLastName: this.patientLastName,
        patientPhone: this.patientPhone,
        patientBirthday: null,
        patientCode: '',
        patientGroups: '',
        patientStateItem: null,
        languageCode: 'lv',
        lastVisit: new Date(),
        patientDoctorName: '',
        photo: null,
        patientAttrator: null,
        state: this.state,
        selectedPlan: 0,
        curingVisit: 0,
        patientSMS: true,
        photos: [],
        teeth: [],
        plans: [],
        visits: [],
        childs: [],
        researches: [],
        audit: [],
        processing: false
      };

      patient = await $app.post('/api/patients', patient);

      this.visible = false;
      this.$emit('created', patient)
    } catch (err) {
      $app.pushError(err);
    }
    this.processing = false;
  }
}
