
import Component from 'vue-class-component';
import { Prop, Vue } from 'vue-property-decorator';

@Component
export default class RemoveManipulation extends Vue {
    @Prop({ default: () => false })
    readonly value: boolean | undefined;

    get visible(): boolean {
      return this.value || false
    }

    set visible(value: boolean) {
      this.$emit('input', value)
    }
}
