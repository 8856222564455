import $app from '@/plugins/modules';
import store from '@/plugins/store'
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import { createSearch, System } from '@/modules/system'
import { IPatient, IPatientSearch } from './types'
import { IPagination } from '../system/types'

const $system = $app.module(System);

@Module({ dynamic: true, store, name: 'customers' })
export default class CustomerStore extends VuexModule {
  private patientSearch: IPatientSearch = {
    items: [],
    filter: createSearch(
      0, 0, null, null,
      {
        patientName: '',
        doctorName: '',
        patientPhone: ''
      }
    )
  }

  get PatientSearch(): IPatientSearch {
    return this.patientSearch
  }

  // ---------------------------------------------------------------------- MUTATIONS

  @Mutation
  setPatientSearch(value: IPatientSearch) {
    value.items.forEach(patient => {
      const patientStateItem = $app.item('PatientStateGlossary', patient.state || 0)
      patient.patientStateItem = patientStateItem || null
    })
    this.patientSearch = value;
  }

  // ---------------------------------------------------------------------- ACTIONS

  @Action({ rawError: true })
  async findPatients(pagination: IPagination) {
    this.setPatientSearch(
      await $system.$module.find('/api/patients/search', this.patientSearch, pagination)
    );
  }

  @Action({ rawError: true })
  async findPatientsByPhone(phone: string): Promise<Array<IPatient>> {
    const pagination: IPagination = {
      append: false,
      itemsPerPage: 100,
      page: 1,
      sortBy: [],
      sortDesc: []
    }

    let search: IPatientSearch = {
      items: [],
      filter: createSearch(
        0, 0, null, null,
        {
          patientName: '',
          doctorName: '',
          patientPhone: phone
        }
      )
    }

    search = await $system.$module.find('/api/patients/search', search, pagination)
    return search.items
  }
}
